import React, { Suspense, useEffect, useState } from 'react';

const ClientOnlyComponent = React.lazy(() => import('./lottieAnimation.component').then((module) => ({ default: module.LottieAnimation })));

export const LottieAnimation = (props: any) => {
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  return isClient ? (
    <Suspense>
      <ClientOnlyComponent {...props} />
    </Suspense>
  ) : null
};